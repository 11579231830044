<template>
  <main class="ifc-wrapper">
    <section
      v-for="{
        id,
        discipline,
        ref,
        description,
        version,
        status,
        last_updated,
        imgPath,
        link,
      } in models"
      :key="id"
      class="ifc-card"
    >
      <div class="card-header">
        <p class="-ntb" style="font-variant: small-caps">
          {{ discipline }}
        </p>
        <p class="-xstb status">{{ status }}</p>
      </div>

      <div class="img-wrapper">
        <img :src="imgPath" />

        <div class="version">
          <p class="-xstr">v.{{ version }}</p>
        </div>
      </div>

      <p class="-xstr">
        {{ description }}
      </p>

      <section
        class="flex justify-space-between align-end gap8"
        style="padding-top: 8px"
      >
        <p class="-xstr" style="text-align: right">
          Last updated at
          <span class="-xstb">{{ last_updated }}</span>
        </p>

        <div class="btn-wrapper" :class="{ isDisabled: !link?.length }">
          <BaseButton
            :state="'primary'"
            :title="btnTitle(link)"
            @click="openModel(link)"
          />
        </div>
      </section>
    </section>
  </main>
</template>

<script>
import BaseButton from "@/components/utils/BaseButton.vue";
export default {
  props: ["models"],
  components: { BaseButton },
  data() {
    return {};
  },
  created() {
    console.log(this.models);
  },
  methods: {
    openModel(link) {
      this.$store.dispatch("msightLink", link);
      this.$store.dispatch("loadIFC", true);
    },
    async toggleFile(model, index) {
      console.log(model.link);

      if (!this.selectedFile) {
        await this.selectFile(model, index);
        console.log(model);
      } else if (this.selectedFile.id === model.id) {
        await this.clearSelection(model);
      } else {
        //clear last selection
        this.models[this.selectedFile.index].isSelected = false;
        await this.selectFile(model, index);
      }
    },
    async selectFile(model, index) {
      model.isSelected = !model.isSelected;
      this.selectedFile = model;
      this.selectedFile.index = index;

      if (!model.link) return;
      await this.$store.dispatch("msightLink", model.link);
    },
    async clearSelection(model) {
      model.isSelected = !model.isSelected;
      this.selectedFile = null;
      await this.$store.dispatch("msightLink", null);
    },
    btnTitle(link) {
      return link ? "Open Model" : "Processing...";
    },
  },
  computed: {
    linkInStore() {
      return this.$store.state.msightLink;
    },
  },
};
</script>

<style scoped>
.ifc-wrapper {
  display: flex;
  flex-flow: column;
  gap: 4px;
}
.ifc-card {
  padding: 1rem;
  display: flex;
  flex-flow: column;
  gap: 8px;
  text-align: left;
  background: var(--white2);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: 3px solid var(--secondary);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.ifc-card:hover .version {
  opacity: 0.75;
}
/* .ifc-card:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.ifc-card:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
} */
.card-header {
  display: flex;
  gap: 8px;
  justify-content: space-between;
}
.btn-wrapper {
  width: 100px;
  height: 28px;
  align-self: flex-end;
}
.isDisabled {
  opacity: 0.2;
  user-select: none;
  pointer-events: none;
}
.img-wrapper {
  position: relative;
  isolation: isolate;
  z-index: 1;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 120px;
  background: white;
  overflow: hidden;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 4px;
}

img {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: white;
}
.version {
  position: absolute;
  z-index: 0;
  padding: 8px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  color: white;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  letter-spacing: 1px;
  transform: translateY(75%);
  opacity: 0;
  transition: 400ms ease;
}
.status {
  color: rgb(254, 168, 0);
  background: rgb(255, 237, 202);
  box-shadow: rgb(254, 168, 0) 0px 0px 0px 1px;
  display: flex;
  justify-content: center;
  border-radius: 1rem;
  width: min-content;
  padding-inline: 8px;
  padding-block: 2px;
  align-self: flex-end;
}
</style>
