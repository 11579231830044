<template>
  <main v-show="!loadIFC" class="main-route-container">
    <div class="header">
      <span class="highlight"></span>
      <h1 class="-h6">MODELS</h1>
    </div>

    <section class="categories-wrapper">
      <div v-for="category in categories" :key="category.id" class="category">
        <section class="category-item">
          <div class="flex gap8">
            <img :src="categoryIcon" />
            <p class="-stb cat-title">{{ category.title }}</p>
          </div>
          <!-- <div class="right-side">
            <div class="updatedAt-wrapper">
              <p class="-xstr">last updated at</p>
              <p class="-xstb">{{ category.updated_at }}</p>
            </div>
          </div> -->
        </section>

        <section>
          <IFCFilesContainer :models="category.models" />
        </section>
      </div>
    </section>
  </main>
  <main v-if="loadIFC" class="main-route-container"><MSightViewer /></main>
</template>

<script>
import { mapGetters } from "vuex";
import MSightViewer from "@/components/projects/msight/MSightViewer.vue";
import IFCFilesContainer from "@/components/projects/msight/IFCFilesContainer.vue";
import BaseButton from "@/components/utils/BaseButton.vue";
import BaseSearchBar from "@/components/utils/BaseSearchBar";
export default {
  name: "MSight",
  components: { MSightViewer, IFCFilesContainer, BaseButton, BaseSearchBar },
  data() {
    return {
      categories: [
        {
          id: 1,
          title: "Full Project",
          updated_at: "Aug 23, 2023",
          models: [
            {
              id: 1,
              discipline: "Federated",
              ref: "IFC-FED-2023-NOC.117-001-R1",
              description:
                "Federated IFC model for the NOC.117 project, version 2023, revision 1. Includes architectural, structural, and mechanical components for integrated coordination.",
              version: "297c1df8-9651-4c14-aea5-7aa78ece31ac",
              status: "D2",
              last_updated: "Aug 23, 2023",
              imgPath: require("../../assets/temp/msight/project-federated.svg"),
              link: "https://msight.ms4aeco.com",
            },
            {
              id: 2,
              discipline: "Architectural",
              ref: "IFC-ARCH-2023-B-002",
              description:
                "Architectural IFC model for the NOC.117 project, version 2023, showcasing interior layouts, finishes, and spatial arrangements.",
              version: "6f5de634-642c-415b-807f-46ff6196a297",
              status: "D2",
              last_updated: "Aug 21, 2023",
              imgPath: require("../../assets/temp/msight/project-architectural.svg"),
              link: "",
            },
            {
              id: 3,
              discipline: "Structural",
              ref: "IFC-STRUCT-2023-C-003",
              description:
                "Structural IFC model for the NOC.117 project, version 2023, detailing load-bearing elements, foundations, and structural connections.",
              version: "fdccada5-c36e-42ff-a7af-503689f59576",
              status: "D2",
              last_updated: "Aug 21, 2023",
              imgPath: require("../../assets/temp/msight/project-structural.svg"),
              link: "",
            },
            {
              id: 4,
              discipline: "Mechanical",
              ref: "IFC-FED-2023-XYZ-001-R1",
              description:
                "Federated IFC model for the NOC.117 project, version 2023, revision 1. Includes architectural, structural, and mechanical components for integrated coordination.",
              version: "90687fb3-5483-4ce8-bb25-31aac9b4ac3b",
              status: "D2",
              last_updated: "Aug 22, 2023",
              imgPath: require("../../assets/temp/msight/project-mechanical.svg"),
              link: "",
            },
          ],
        },

        {
          id: 2,
          title: "BL01. Main-Entry Block",
          updated_at: "Aug 26, 2023",

          models: [
            {
              id: 5,
              discipline: "Federated",
              ref: "IFC-FED-2023-BL01-005-R2",
              description:
                "Federated IFC model for the BL01 Unit, version 2023, revision 2. Includes architectural, structural, and mechanical elements for holistic project coordination.",
              version: "9bdb33c2-33e1-4bc0-b08d-8c75df74125f",
              status: "D3",
              last_updated: "Aug 26, 2023",
              imgPath: require("../../assets/temp/msight/unit1-federated.svg"),
              link: "",
            },
            {
              id: 6,
              discipline: "Architectural",
              ref: "IFC-ARCH-2023-BL01-008-V1",
              description:
                "Architectural IFC model for the BL01 Unit, version 2023, branch 008. Showcases interior layouts, finishes, and spatial arrangements with an emphasis on design integration.",
              version: "512b853d-5508-4048-a250-38720a8c4999",
              status: "D3",
              last_updated: "Aug 25, 2023",
              imgPath: require("../../assets/temp/msight/unit1-architectural.svg"),
              link: "https://aa.ms4aeco.com",
            },
            {
              id: 7,
              discipline: "Structural",
              ref: "IFC-STRUCT-2023-BL01-011-PH2",
              description:
                "Structural IFC model for the BL01 Unit, version 2023, part of Phase 2. Elaborates on load-bearing components, foundation specifics, and structural connectivity.",
              version: "1b1e71e8-c439-46fd-9a69-bb2674c849fa",
              status: "D3",
              last_updated: "Aug 24, 2023",
              imgPath: require("../../assets/temp/msight/unit1-structural.svg"),
              link: "https://ae.ms4aeco.com",
            },
            {
              id: 8,
              discipline: "Mechanical",
              ref: "IFC-MECH-2023-BL01-014-SYS",
              description:
                "Mechanical IFC model for the BL01 Unit, version 2023, designated for Systems. Covers HVAC, plumbing, and other mechanical components in detail.",
              version: "1e27f174-aa74-454c-bbb3-f41c1c2557fb",
              status: "D3",
              last_updated: "Aug 26, 2023",
              imgPath: require("../../assets/temp/msight/unit1-mechanical.svg"),
              link: "https://am.ms4aeco.com",
            },
          ],
        },

        {
          id: 3,
          title: "BL02. Eastern Block",
          updated_at: "Aug 26, 2023",

          models: [
            {
              id: 9,
              discipline: "Federated",
              ref: " IFC-FED-2023-BL02-006-R1",
              description:
                "Federated IFC model for the BL02 Development, version 2023, revision 1. Integrates architectural, structural, and mechanical components for interdisciplinary alignment.",
              version: "ca747e2d-31ff-42c7-8497-d575c9ce416d",
              status: "D3",
              last_updated: "Aug 26, 2023",
              imgPath: require("../../assets/temp/msight/unit2-federated.svg"),
              link: "",
            },
            {
              id: 10,
              discipline: "Architectural",
              ref: "IFC-ARCH-2023-BL02-009-V3",
              description:
                "Architectural IFC model for the BL02 Development, version 2023, branch 009. Incorporates interior aesthetics, circulation patterns, and updated room configurations.",
              version: "565bd99b-d2c9-43e1-a6bc-1c4e439e6fa7",
              status: "D3",
              last_updated: "Aug 25, 2023",
              imgPath: require("../../assets/temp/msight/unit2-architectural.svg"),
              link: "https://ba.ms4aeco.com",
            },
            {
              id: 11,
              discipline: "Structural",
              ref: "IFC-STRUCT-2023-BL02-012-PH1",
              description:
                "Structural IFC model for the BL02 Unit, version 2023, part of Phase 1. Explores intricate load distributions, seismic resistance measures, and foundation details.",
              version: "5f9e42d9-a3c6-4758-9172-fbf2eef5faff",
              status: "D2",
              last_updated: "Aug 24, 2023",
              imgPath: require("../../assets/temp/msight/unit2-structural.svg"),
              link: "https://be.ms4aeco.com",
            },
            {
              id: 12,
              discipline: "Mechanical",
              ref: "IFC-MECH-2023-BL02-015-SYS",
              description:
                "Mechanical IFC model for the BL02 Unit, version 2023, designated for Systems. Includes HVAC layouts, plumbing fixtures, and equipment placements.",
              version: "e6cbfc9a-a416-4dfe-8edc-26ac3a2777dd",
              status: "D2",
              last_updated: "Aug 26, 2023",
              imgPath: require("../../assets/temp/msight/unit2-mechanical.svg"),
              link: "https://bm.ms4aeco.com",
            },
          ],
        },

        {
          id: 4,
          title: "BL03. Office Complex",
          updated_at: "Aug 31, 2023",

          models: [
            {
              id: 13,
              discipline: "Federated",
              ref: "IFC-FED-2023-BL03-007-R3",
              description:
                " Federated IFC model for the BL03 Project, version 2023, revision 3. Merges architectural, structural, and mechanical elements to ensure project-wide compatibility.",
              version: "b9a480d4-faed-48f0-88fa-7c313021ae42",
              status: "D2",
              last_updated: "Aug 31, 2023",
              imgPath: require("../../assets/temp/msight/unit3-federated.svg"),
              link: "",
            },
            {
              id: 14,
              discipline: "Architectural",
              ref: "IFC-ARCH-2023-BL03-010-V2",
              description:
                "Architectural IFC model for the BL03 Office Complex Unit, version 2023, branch 010. Focuses on spatial organization, material selection, and interior/exterior design coherence.",
              version: "fb8394bf-4f51-4951-8f97-8e21fbfac202",
              status: "D2",
              last_updated: "Aug 30, 2023",
              imgPath: require("../../assets/temp/msight/unit3-architectural.svg"),
              link: "https://ca.ms4aeco.com",
            },
            {
              id: 15,
              discipline: "Structural",
              ref: "IFC-STRUCT-2023-BL03-013-PH4",
              description:
                "Structural IFC model for the BL03 Unit, version 2023, part of Phase 4. Delves into comprehensive load analyses, structural member connections, and foundation systems.",
              version: "0f2b7446-84e7-4a4c-a80c-b3e3fe2f4905",
              status: "D2",
              last_updated: "Aug 30, 2023",
              imgPath: require("../../assets/temp/msight/unit3-structural.svg"),
              link: "https://ce.ms4aeco.com",
            },
            {
              id: 16,
              discipline: "Mechanical",
              ref: "IFC-MECH-2023-BL03-016-SYS",
              description:
                "Mechanical IFC model for the BL03 Unit, version 2023, designated for Systems. Showcases HVAC design, plumbing connections, and equipment coordination.",
              version: "4587519f-c28e-4448-adfe-2efe58479426",
              status: "D2",
              last_updated: "Aug 31, 2023",
              imgPath: require("../../assets/temp/msight/unit3-mechanical.svg"),
              link: "https://cm.ms4aeco.com",
            },
          ],
        },
      ],
    };
  },
  created() {
    this.$store.dispatch("loadIFC", null);
    this.$store.dispatch("msightLink", null);
  },
  computed: {
    ...mapGetters(["loadIFC"]),
    categoryIcon() {
      return require("@/assets/icons/miscellaneous/cube.svg");
    },
  },
};
</script>

<style scoped>
.main-route-container {
  width: 100%;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  overflow: auto;
  transition: 200ms ease;
}
.actions {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  margin-top: 1rem;
}
.search-container {
  width: 272px;
  text-align: right;
}
.btn-container {
  width: 164px;
  min-height: 32px;
}

.header {
  display: flex;
  gap: 8px;
  align-items: center;
}
.highlight {
  display: inline-block;
  height: 16px;
  width: 1px;
  border-radius: 8px;
  border: 2px solid var(--highlight);
  background: var(--highlight);
}
.categories-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  user-select: none;
  animation: slide-in-top-menu 0.6s ease-out forwards;
}
.category {
  position: relative;
  isolation: isolate;
  display: flex;
  flex-flow: column;
  gap: 8px;
  width: 400px;
  border-radius: 4px;
}
.category-item {
  position: sticky;
  z-index: 2;
  top: -12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 5px;
  background: linear-gradient(
    156deg,
    rgba(230, 247, 255, 1) 25%,
    rgba(215, 232, 255, 1) 100%
  ) !important;
  border-radius: 4px;
  border: 2px solid var(--secondary);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.category:hover .cat-title {
  color: var(--info);
}

/* .category:first-child {
  border-top: 1px solid var(--gray6);
} */
/* .category:not(:last-child) {
  border-bottom: 1px solid var(--gray6);
} */

.category > p {
  transition: 200ms;
}
.right-side {
  display: flex;
  align-items: center;
}
.updatedAt-wrapper {
  color: var(--gray2);
  display: flex;
  gap: 4px;
  opacity: 0.8;
}

.isToggled {
  transform: rotateZ(90deg);
}
.isToggledBackground {
  background: linear-gradient(
    156deg,
    rgba(230, 247, 255, 1) 25%,
    rgba(215, 232, 255, 1) 100%
  ) !important;

  border-top: 1px solid var(--secondary);
}
</style>
